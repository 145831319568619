import React from 'react';
import styles from './index.module.less';
// import Image from 'next/image';

const ComparePhotosContent = () => {
  return (
    <div className={styles.content}>
      <video
        className={styles.leftImage}
        src="/images/hairstyle/index-top.mp4"
        autoPlay
        loop
        muted
        playsInline
      />
    </div>
  );
};

export default ComparePhotosContent;
