import React, { useRef, useEffect, useState } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Affix, Button } from 'antd';
import Image from 'next/image';
import { ArrowRightOutlined } from '@ant-design/icons';
import ComparePhotosContent from '@/components/Common/ComparePhotosContent';
import { HairstyleMainListModel } from '@/types/home';
import { useWindowSize } from 'react-use';
import classNames from 'classnames';

export const sortColorData: any = () => {
  return {
    Trending: {
      color: '#E097FF',
      backgroundColor: 'rgba(152, 115, 255, 0.30)',
      title: (
        <>
          20K+ Viral <span style={{ color: '#E097FF' }}>Trending</span> & Influencer Styles
        </>
      ),
      desc: 'Try on the most iconic hairstyles inspired by 2025 trends, top fashioned influencers,clebrites, and hottest style movements Instantly.',
      btnTitle: 'Explore Fresh Trends',
      introDesc: 'Explore More Hottest Hairstyles Now',
      h3List: [
        '2025 hairstyle Global Trend Collections | Weekly Updates',
        'Celebrity Style Matching | 3000+ Verified Looks',
        'explore 500+ hair cut fashioned influencers',
        "Discover your idol's hairstyle",
      ],
    },
    'Hair Types': {
      color: '#FFD15C',
      backgroundColor: 'rgba(255, 209, 92, 0.30)',
      title: (
        <>
          Find your perfect haircut match for your{' '}
          <span style={{ color: '#FFE57B' }}>hair Types</span>
        </>
      ),
      desc: 'Effortlessly try on personalized hairstyles with Hairpaca—for thin, thick, curly, or any hair type. Instantly explore cute, cool, elegant, or radiant looks that perfectly match your style! Over 20,000+ styles await you.',
      btnTitle: '3000+ Hair Types Listed',
      introDesc: 'Browse more hair types you want Now',
      h3List: [
        'Short/Medium/Long Style Explorer',
        'Pixie cuts for oval faces',
        'Shoulder-length layered looks',
        'short hairstyles for thick hair',
        'Beach wave simulation for wavy hair',
      ],
    },
    'Face Shapes': {
      color: '#58CF79',
      backgroundColor: 'rgba(88, 207, 121, 0.30)',
      title: (
        <>
          Best haircut Tailored to Your Unique <span style={{ color: '#74FF9B' }}>Face Shapes</span>
        </>
      ),
      desc: "Explore a world of hairstyles tailored to your unique face shape. Whether you're aiming for a cute, edgy, professional, glamorous, or sultry look—or anything in between—Hairpaca has the perfect match for you. Instantly transform your style and see your new look come to life effortlessly.",
      btnTitle: '3000+ Face Shapes Listed',
      introDesc: 'Find Hairstyles best match Your Face Shape',
      h3List: ['Instant Face Shape choose to See Result', 'short hairstyles face shape'],
    },
    Colors: {
      color: '#68F3F6',
      backgroundColor: 'rgba(104, 243, 246, 0.30)',
      title: (
        <>
          Hair <span style={{ color: '#68F3F6' }}>Colors</span> Virtual Try-On with Real-Time
          Simulation
        </>
      ),
      desc: "With hairpaca's hair color changer, you can explore 12345+ trendy shades and create a virtual makeover in seconds. Whether you're looking for a bold new look or subtle highlights, experimenting has never been easier—or more fun! Share your stunning new style and wow your friends!",
      btnTitle: '3000+ Colors Try-on',
      introDesc: 'Change Your Hair Color, Discover a New You!',
      h3List: [
        'Live Color Rendering | Next-Gen Forecast Edition',
        'change hair colour in photo',
        'Pro-Approved hair color',
      ],
    },
  };
};

const TopContent = ({
  total,
  dataList,
  onStylingVisibleChanged,
}: {
  total: number;
  dataList?: HairstyleMainListModel[];
  onStylingVisibleChanged?: (isVisible: boolean) => void;
}) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const stylingRef = useRef(null);
  const ref = useRef(null);
  const { width } = useWindowSize();
  const [isStylingVisible, setIsStylingVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsStylingVisible(entry.isIntersecting);
        onStylingVisibleChanged && onStylingVisibleChanged(entry.isIntersecting && width < 768);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );

    if (stylingRef.current) {
      observer.observe(stylingRef.current);
    }

    return () => {
      if (stylingRef.current) {
        observer.unobserve(stylingRef.current);
      }
    };
  }, [stylingRef, width, onStylingVisibleChanged]);

  const onDashboardClick = () => {
    router.push(`/influencers`);
  };

  const getBottomContainer = (showTitle: boolean) => {
    return (
      <div
        className={classNames([styles.bottomContainer, !showTitle && styles.bottomScrollContainer])}
      >
        {showTitle && <span className={styles.stylingBy}>{t('Styling by:')}</span>}
        {dataList?.map((item) => {
          const tempColorData = sortColorData()[`${item?.main_label}`];
          return (
            <span
              key={item?.main_label}
              className={styles.sortSpan}
              style={{
                color: tempColorData?.color,
                backgroundColor: tempColorData?.backgroundColor,
              }}
              onClick={() => {
                router.push(`/#${item?.main_label?.toLocaleLowerCase()}`);
              }}
            >
              {item?.main_label}
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <div ref={ref}>
      <div className={styles.content}>
        <div className={styles.leftContainer} id={'leftContainer'}>
          <div className={styles.titleDesc}>
            {t('Welcome to')} <span style={{ color: '#68F3F6' }}>Hairpaca</span>
          </div>
          <h1 className={styles.title}>
            {t('AI Hairstyle Simulator: Discover 2025 Trends for Your Face Shape')}
          </h1>
          <p>
            <span>{t('Hairpaca #1 AI virtual hairstyle changer.Try on over newest')}</span>
            <span>{total}</span>
            <span>
              {t('trendy hairstyles, tailored for your face shape, hair type, and color. Join')}
            </span>
            <span>{t('7986+')}</span>
            <span>{t('happy users exploring the future of hairstyling!')}</span>
          </p>
          <div className={styles.btnContainer}>
            <Button className={styles.btn} onClick={() => onDashboardClick()}>
              {'Start Trendy Styling Now'}
              <ArrowRightOutlined />
              {/* </Tooltip> */}
            </Button>
          </div>
          <div className={styles.manyCustomer}>
            <Image
              src={'/images/hairstyle/index-many-up.webp'}
              style={{ verticalAlign: 'bottom', marginRight: '15px' }}
              alt=""
              width={61}
              height={25}
            />
            <div className={styles.trustedContainer}>
              <span>{t('Trusted by')}</span>
              <span> {t('1,539')} </span>
              <span>{t('satisfied customers')}</span>
            </div>
          </div>
        </div>

        <div className={styles.rightContainer}>
          <ComparePhotosContent />
        </div>
      </div>

      <div
        ref={stylingRef}
        style={{ padding: '30px 0 50px', borderBottom: '1px solid rgba(217, 217, 217, 0.3)' }}
      >
        {getBottomContainer(true)}
      </div>

      {width < 768 && !isStylingVisible && (
        <Affix offsetTop={69}>{getBottomContainer(false)}</Affix>
      )}
    </div>
  );
};

export default TopContent;
