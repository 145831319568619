import React, { useState } from 'react';
import { CommonBaseContent } from '../Layouts/CommonBackContent';
import dynamic from 'next/dynamic';
import styles from './index.module.less';
import { HairstyleMainListModel } from '@/types/home';
import TopContent from './TopContent';
import { useConfigProvider } from '@/context/ConfigProvider';
const ChooseContent = dynamic(() => import('./ChooseContent'));
const ReviewsContent = dynamic(() => import('./ReviewsContent'));
const FAQContent = dynamic(() => import('../FAQContent'));
const MeetMyBabyContent = dynamic(() => import('./MeetMyBabyContent'));
const FeaturesContent = dynamic(() => import('./FeaturesContent'));
const TopInfluencersFollowContent = dynamic(() => import('./TopInfluencersFollowContent'));
const StyleContent = dynamic(() => import('./StyleContent'));
const HowUseContent = dynamic(() => import('./HowUseContent'));
const BlogContent = dynamic(() => import('./BlogContent'));

const HairStyleContent = ({ data }: { data?: any }) => {
  const hairstyleCount = data?.hairstyleCount;
  const dataList: HairstyleMainListModel[] = data?.itemList;
  const [isStylingVisible, setIsStylingVisible] = useState(false);
  const { isMobile } = useConfigProvider();

  return (
    <>
      <CommonBaseContent>
        <div className={styles.content}>
          <TopContent
            total={hairstyleCount}
            dataList={data?.itemList}
            onStylingVisibleChanged={(isVisible) => {
              setIsStylingVisible(isVisible);
            }}
          />

          {dataList?.length > 0 && (
            <div
              id={dataList?.length > 0 ? dataList[0]?.main_label?.toLocaleLowerCase() : ''}
              style={{ scrollMarginTop: isMobile ? '50px' : '100px' }}
            >
              <StyleContent data={dataList[0]} />
            </div>
          )}
          <FeaturesContent hairstyleTotal={hairstyleCount} bloggerTotal={data?.upsListRes?.total} />
          <TopInfluencersFollowContent upsList={data?.upsListRes?.data} />

          {dataList?.slice(1).map((item, index) => {
            return (
              <div
                key={index + item.main_label}
                id={item?.main_label?.toLocaleLowerCase() ?? ''}
                style={{ scrollMarginTop: isMobile ? '50px' : '100px' }}
              >
                <StyleContent data={item} />
              </div>
            );
          })}

          <HowUseContent />
        </div>
      </CommonBaseContent>
      <ReviewsContent />
      <CommonBaseContent>
        {/* <ReviewsContent /> */}
        <ChooseContent />
        <FAQContent isFromHome={true} pageContent={data.pageContent} />
        <BlogContent
          title={data?.pageContent?.contentInfo['section-top-blog']?.title}
          data={data?.pageContent?.topBlogList}
        />
        <BlogContent
          title={data?.pageContent?.contentInfo?.['section-blog']?.title}
          data={data?.pageContent?.blogList}
        />
        <MeetMyBabyContent stylingVisible={isStylingVisible} />
      </CommonBaseContent>
    </>
  );
};

export default HairStyleContent;
