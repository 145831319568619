import React from 'react';
import styles from './index.module.less';
import { Button, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const RegisterSuccessAlert = ({
  openType,
  title,
  desc,
  btnTitle1,
  btnTitle2,
  handleCancel,
  handleClick1,
  handleClick2,
}: {
  openType?: boolean;
  title?: string;
  desc?: string | React.ReactNode;
  btnTitle1?: string;
  btnTitle2?: string;
  handleCancel?: () => void;
  handleClick1?: () => void;
  handleClick2?: () => void;
}) => {
  return (
    <Modal
      width={380}
      styles={{
        body: {
          background: 'var(--foreground-rgb)',
          borderRadius: '5px',
          padding: 0,
        },
      }}
      // bodyStyle={{ background: 'red' }}
      open={openType}
      closeIcon={<CloseOutlined style={{ color: 'var(--app-text-color)' }} />}
      onCancel={handleCancel}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <span>{title}</span>
        </div>
        <div className={styles.bottomContainer}>
          <div>{desc}</div>
          {btnTitle1 && (
            <Button className={styles.firstBtn} onClick={handleClick1}>
              {btnTitle1}
            </Button>
          )}
          {btnTitle2 && (
            <Button className={styles.secondBtn} onClick={handleClick2}>
              {btnTitle2}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RegisterSuccessAlert;
