import type { GetStaticProps, InferGetServerSidePropsType } from 'next';
import { NextPageWithLayout } from '@/pages/_app';
import React, { useState, useEffect } from 'react';
import Layout from '../layouts';
import { AppSettings, lowerName } from '@/shared/app-common';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import {
  getHairstyleCountData,
  getHairstyleMainData,
  getHomeIndexUpInfoListApi,
  getPageContentContentApi,
} from '@/lib/service';
import { useRouter } from 'next/router';
import RegisterSuccessAlert from '@/components/Common/RegisterSuccessAlert';
import { useTranslation } from 'next-i18next';
import CustomHead from '@/components/Common/CustomHead';
import { canonicalUrl } from '@/shared/utils';
import { getTdkApi } from '@/lib/service/tdk';
import HairStyleContent from '@/components/HairStyleHome';
import { homeJsonLd } from '@/lib/jsonld';

export const getStaticProps: GetStaticProps = async (context: any) => {
  const { locale } = context;
  const resolvedUrl = '/';

  const [upsListRes, hairstyleCountRes, localeRes, itemRes, pageContent, tdkData] =
    await Promise.all([
      getHomeIndexUpInfoListApi(3, locale),
      getHairstyleCountData(),
      serverSideTranslations(locale, ['common']),
      getHairstyleMainData(),
      getPageContentContentApi(`/`, locale, '', ''),
      getTdkApi(resolvedUrl, locale),
    ]);

  const tempData = localeRes['_nextI18Next']['initialI18nStore'][locale]['common'] ?? {};
  const jsonLd = homeJsonLd({
    title: tdkData?.data?.title ?? tempData?.hairstyle_meta_Title,
    desc: tdkData?.data?.description ?? tempData?.hairstyle_meta_Desc,
    url: canonicalUrl(process.env.NEXT_PUBLIC_HOST || '', resolvedUrl, locale),
  });
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      ...localeRes,
      upsListRes: { ...(upsListRes ?? {}) },
      hairstyleCount: hairstyleCountRes?.data,
      itemList: itemRes?.data ?? [],
      herf: canonicalUrl(process.env.NEXT_PUBLIC_HOST || '', resolvedUrl, locale),
      title: tdkData?.data?.title ?? tempData[`${lowerName}_meta_Title`],
      description: tdkData?.data?.description ?? tempData[`${lowerName}_meta_Desc`],
      hasSurveyReward: false,
      pageContent: pageContent?.data,
      jsonLd,
    },
    revalidate: 60,
  };
};

const Home: NextPageWithLayout<InferGetServerSidePropsType<typeof getStaticProps>> = (
  props: any,
) => {
  const [showAlert, setShowAlert] = useState(false);
  const router = useRouter();
  const { t } = useTranslation('common');
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const content = (
    <div>
      <HairStyleContent data={props} />
      {isClient && (
        <RegisterSuccessAlert
          openType={showAlert}
          title={t('Thanks for completing the feedback form!')}
          desc={
            <>
              <div style={{ color: '#68F3F6' }}>{t('You have received 5 free try-ons.')}</div>
              <div style={{ color: 'var(--app-text-color)' }}>
                {t('Try Out Some Trendy Hairstyles Now!')}
              </div>
            </>
          }
          btnTitle1={t('EXPLORE TRENDY STYLES')}
          handleCancel={() => {
            setShowAlert(false);
          }}
          handleClick1={() => {
            router.push('/hairstyle');
            setShowAlert(false);
          }}
          {...props}
        />
      )}
    </div>
  );

  return content;
};

Home.getLayout = function getLayout(page: React.ReactElement) {
  const title = page?.props?.title ?? '';
  const desc = page?.props?.description ?? '';
  const jsonLd = page?.props?.jsonLd;

  return (
    <Layout
      head={
        <CustomHead>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={desc} />

          <meta property="og:title" content={title} />
          <meta property="og:description" content={desc} />
          <meta property="og:image" content={AppSettings.logoUrl} />
          <meta property="og:image:secure_url" content={AppSettings.logoUrl} />
          <meta property="og:type" content="summary" />
          <meta property="og:url" content={page.props?.herf} />

          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={desc} />
          <meta name="twitter:image" content={AppSettings.logoUrl} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={page.props?.herf} />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="canonical" href={page.props?.herf} />
          {jsonLd && (
            <script
              async
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
            />
          )}
          {/* 预加载关键 CSS */}
          <link rel="preload" href="/styles/fonts.css" as="style" />

          {/* 预加载关键字体 */}
          <link
            rel="preload"
            href="/fonts/Poppins-Regular.otf"
            as="font"
            type="font/otf"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href="/fonts/Poppins-Medium.otf"
            as="font"
            type="font/otf"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href="/fonts/Poppins-Bold.otf"
            as="font"
            type="font/otf"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href="/fonts/CrimsonText-Italic.ttf"
            as="font"
            type="font/ttf"
            crossOrigin="anonymous"
          />
          {/* 预连接到关键域名 */}
        </CustomHead>
      }
    >
      {page}
    </Layout>
  );
};

export default Home;
